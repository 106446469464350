import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Independence Day"
        paragraph="Elfonze celebrate 75th India's Independence Day at Bengaluru Office!"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/media-coverage/media-2.png" alt="" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont">
                          <h4 className="extra-title">
                          Elfonze celebrate 75th India's Independence Day at Bengaluru Office!
                          </h4>
                          <div className="spacial">
                            <p>
                              Elfonzites celebrated India's 75th Independence Day with utmost zeal and reverence, honoring the sacrifices of freedom fighters and soldiers. The workplace was adorned with tricolor decorations, and employees showcased their cultural talents through various activities.
                            </p>
                          </div>
                          <p>
                            The event featured Major R D Bhargava, a distinguished guest with a rich background in the military and corporate sectors. His speech highlighted the importance of discipline, dedication, and integrity in achieving success, while also addressing modern challenges like cyber threats.
                          </p>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-10">
                                <img src="/img/media-coverage/media-2.1.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                          <p>
                            Elfonze Technologies also promoted the #HarGharTiranga initiative, advocating for the proper disposal and storing of the Indian National Flag. Guidelines included burning or burying damaged flags with dignity, emphasizing the flag's significance as a symbol of pride.
                          </p>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/media-2.2.jpg" alt="" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/media-2.3.jpg" alt="" />
                              </div>
                            </div>
                          </div>
                          
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Independence Day</title>
      <meta key="description" name="description" 
        content="Elfonze celebrate 75th India's Independence Day at Bengaluru Office!" />
    </>
  )
}

export default BlogDetailsDark;
